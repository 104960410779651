// In your CustomerExistenceContext definition file
import React, { createContext, useContext, useState } from 'react';

const CustomerExistenceContext = createContext();

export const CustomerExistenceProvider = ({ children }) => {
    const [doesCustomerExist, setDoesCustomerExist] = useState(false);
    const [isCustomerDataLoaded, setIsCustomerDataLoaded] = useState(false); // New state to track loading

    return (
        <CustomerExistenceContext.Provider value={{ doesCustomerExist, setDoesCustomerExist, isCustomerDataLoaded, setIsCustomerDataLoaded }}>
            {children}
        </CustomerExistenceContext.Provider>
    );
};

// Export a hook for easy context consumption
export const useCustomerExistence = () => useContext(CustomerExistenceContext);
