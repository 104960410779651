import { useState, useEffect } from 'react';

const useProductLogic = (products, searchTerm) => {
    const [filteredProducts, setFilteredProducts] = useState([]);

    useEffect(() => {
        const result = products.filter(product =>
            (product.Description && product.Description.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (product.ProductCode && product.ProductCode.toLowerCase().includes(searchTerm.toLowerCase()))
        );

        const formattedProducts = result.map(product => ({
            name: product.Description,
            productCode: product.ProductCode,
            quantity: product.Quantity,
            unitPrice: product.UnitPrice?.amount || 0,
            totalAmount: product.Amount?.amount || 0,
        }));

        setFilteredProducts(formattedProducts);
    }, [products, searchTerm]);

    return filteredProducts;
}

export default useProductLogic;