import React from 'react';
import {
    Modal,
    Box,
    Card,
    CardContent,
    Typography,
    IconButton,
    CardActions,
    Grid,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Barcode from 'react-barcode';

// Modal style for consistent aesthetics
const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    padding: 3,
    borderRadius: 8,
};

// Header component with title and close button
const ModalHeader = ({ title, onClose }) => (
    <Grid container>
        <Grid item xs={12} sx={{ textAlign: 'right' }}>
            <IconButton aria-label="close" onClick={onClose}>
                <CloseIcon />
            </IconButton>
        </Grid>
        <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                {title}
            </Typography>
        </Grid>
    </Grid>
);

// Component to display transaction details
const TransactionDetails = ({ transaction, formatDate }) => (
    <>
        <Typography variant="body2" color="text.secondary">
            Date: {formatDate(transaction.createdDate)}
        </Typography>
        <Typography variant="body2" color="text.secondary">
            Order #: {transaction.orderId}
        </Typography>
        {transaction.items.map((item) => (
            <Typography variant="body1" key={item.itemId}>
                {item.name} - Qty: {item.quantity} - ${item.price}
            </Typography>
        ))}
        <Typography variant="body1" sx={{ marginTop: 2, fontWeight: 'bold' }}>
            Total: ${transaction.finalTotal}
        </Typography>
    </>
);

// Component for payment details
const PaymentDetails = ({ paymentDetails }) => {
    if (!paymentDetails || paymentDetails.length === 0) {
        return (
            <Typography variant="body2" color="text.secondary">
                Payment Details Unavailable
            </Typography>
        );
    }
    return (
        <Typography variant="body2" color="text.secondary">
            Payment: ****{paymentDetails[0].last4Digits} ({paymentDetails[0].aid_Label || 'Label not available'})
        </Typography>
    );
};

// Component to display the barcode
const BarcodeDisplay = ({ orderId }) => (
    <Grid item xs={12} sx={{ textAlign: 'center', padding: '16px 0' }}>
        <Barcode
            value={String(orderId)}
            format="CODE128"
            height={40}
        />
    </Grid>
);

// Refactored UserTransactionsModal component
const UserTransactionsModal = ({ isOpen, onRequestClose, transaction }) => {
    const formatDate = (isoDate) => {
        if (!isoDate) return 'Date unavailable';
        const date = new Date(isoDate);
        return date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
        });
    };

    if (!transaction) return null;

    return (
        <Modal
            open={isOpen}
            onClose={onRequestClose}
            aria-labelledby="transaction-modal-title"
            aria-describedby="transaction-modal-description"
        >
            <Box sx={modalStyle}>
                <Card raised sx={{ padding: 2 }}>
                    <CardContent>
                        <ModalHeader title="Receipt" onClose={onRequestClose} />
                        <TransactionDetails transaction={transaction} formatDate={formatDate} />
                        <PaymentDetails paymentDetails={transaction.paymentDetails} />
                        <BarcodeDisplay orderId={transaction.orderId} />
                    </CardContent>
                </Card>
            </Box>
        </Modal>
    );
};

export default UserTransactionsModal;
