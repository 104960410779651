import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';

const UserGreeting = ({ customerData }) => {
  if (!customerData || !customerData.customer) {
    console.log('No customer data available');
    return null;
  }
  const { customer, loyaltyCard  } = customerData;
  console.log(customerData)

  return (
    <Card sx={{ minWidth: 275, margin: 2 }}> {/* Adjust margin as needed */}
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Greetings
        </Typography>
        <Typography variant="h5" component="div">
          Welcome back, {customer.first_name}!
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          We're glad to see you again. Here's what's new:
        </Typography>
        {/* Display user's email */}
        <Typography variant="subtitle1">
          How many Points You Got: {loyaltyCard.loyaltypoints}
        </Typography>
        {/* Display user's spend history for this year if available */}
      </CardContent>
    </Card>
  );
};

export default UserGreeting;
