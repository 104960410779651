import React, { useState, useRef, useEffect } from 'react';
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber, signInWithCredential, PhoneAuthProvider } from 'firebase/auth';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'; // Ensure you import the CSS for react-phone-input-2
import { Container, TextField, Button, Typography, Box, Alert } from '@mui/material';


const Login = () => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [code, setCode] = useState('');
    const [verificationId, setVerificationId] = useState('');
    const [error, setError] = useState('');
    const { currentUser } = useAuth();

    const navigate = useNavigate();
    const auth = getAuth();
    const recaptchaVerifierRef = useRef(null);

    const handlePhoneNumberChange = (value) => {
        // Ensure the value includes a plus sign at the beginning
        const formattedNumber = value.startsWith('+') ? value : `+${value}`;
        setPhoneNumber(formattedNumber);
    };

    useEffect(() => {
        if (currentUser) {
            navigate('/dashboard');
        }
    }, [currentUser, navigate]);

    useEffect(() => {
        recaptchaVerifierRef.current = new RecaptchaVerifier(auth, 'recaptcha-container', {
            'size': 'invisible',
        });
    }, [auth]);

    const handleSendCode = async () => {
        // Validate phone number length before attempting to send the code
        if (phoneNumber.length < 12 || phoneNumber.length > 14) {
            setError('Please enter a valid phone number.');
            return; // Prevent further execution if validation fails
        }

        try {
            console.log(`Sending code to: ${phoneNumber}`); // Debug log

            const confirmationResult = await signInWithPhoneNumber(auth, phoneNumber, recaptchaVerifierRef.current);
            setVerificationId(confirmationResult.verificationId);
            setError(''); // Reset error message on successful sending
        } catch (error) {
            console.error("Error during signInWithPhoneNumber", error);
            setError('Failed to send verification code. Please try again.');
        }
    };

    const handleVerifyCode = async () => {
        try {
            const credential = PhoneAuthProvider.credential(verificationId, code);
            await signInWithCredential(auth, credential);
            navigate('/dashboard');
        } catch (error) {
            console.error("Error during verification", error);
            setError('Verification failed. Please enter the correct code.');
        }
    };

    // Inline styles simplified for clarity and focus on main adjustments
    const inputStyle = {
        margin: '10px 0',
        padding: '10px',
        fontSize: '16px',
        width: '100%', // Full width for better responsiveness
        maxWidth: '300px', // Limit max width for larger screens
        borderRadius: '5px',
        border: '1px solid #ccc',
    };

    const buttonStyle = {
        margin: '10px 0',
        padding: '10px 15px', // Adjust padding for better visual appearance
        fontSize: '16px',
        fontWeight: 'bold', // Make text bolder
        letterSpacing: '1px', // Space out letters slightly
        width: '100%',
        borderRadius: '5px',
        border: 'none',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)', // Soft shadow for depth
        backgroundColor: '#4CAF50', // Consider using your theme's primary color
        color: 'white',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#43a047', // A slightly darker shade on hover
        },
    };

    const containerStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh', // Use minHeight for full height while allowing content to grow
        padding: '20px',
        boxSizing: 'border-box',
        backgroundColor: '#f5f5f5', // A light background color for the whole page
    };

    return (
        <Box sx={{ bgcolor: '#f5f5f5', minHeight: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <Container component="main" maxWidth="xs" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px', boxSizing: 'border-box' }}>
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography component="h1" variant="h5">
                        Log in
                    </Typography>
                    <Typography sx={{ mt: 2 }}>
                        If you are a Loyalty customer of Five Spice Indian Grocery San Jose, you can log into your Loyalty account to see your points and your past 10 purchases.
                    </Typography>

                    <PhoneInput
                        country={'us'}
                        value={phoneNumber}
                        onChange={handlePhoneNumberChange}
                        containerStyle={{ width: '100%', marginTop: '20px' }}
                        inputStyle={{ width: '100%', height: '40px' }}
                    />
                    <Button
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2, buttonStyle }}
                        onClick={handleSendCode}

                    >
                        Send Code
                    </Button>
                    {verificationId && (
                        <>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                label="Verification Code"
                                name="code"
                                value={code}
                                onChange={(e) => setCode(e.target.value)}
                                autoComplete="code"
                                sx={{
                                    '& label.Mui-focused': {
                                        color: 'primary.main',
                                    },
                                    '& .MuiInput-underline:after': {
                                        borderBottomColor: 'primary.main',
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        backgroundColor: 'background.paper', // Use theme's background color for paper if it fits well
                                        '& fieldset': {
                                            borderColor: '#ccc',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: 'primary.light',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: 'primary.main',
                                        },
                                    },
                                    // If you have additional styles from inputStyle, make sure they're included here or merged appropriately
                                }}
                            />
                            <Button
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2, buttonStyle }}
                                onClick={handleVerifyCode}
                            >
                                Verify Code
                            </Button>
                        </>
                    )}
                    {error && <Alert severity="error" sx={{ width: '100%', mt: 2 }}>{error}</Alert>}
                    <div id="recaptcha-container"></div>
                </Box>
            </Container>
        </Box>
    );
};

export default Login;


