import React from 'react';
import styles from './SiteUpdates.module.css'; // Make sure to create this CSS module
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom

const SiteUpdates = () => {
    const navigate = useNavigate(); // Initialize the navigate function

    return (
        <div className={styles.container}>
            <h1 className={styles.header}>What's Coming Up at Five Spice Indian Grocery</h1>
            <ul className={styles.updatesList}>
                <li>E-commerce Platform Launch - Shop your favorite spices online!</li>
                <li>Improved Loyalty App Experience - Easier and more rewarding!</li>
                <li>Delivery Options - Get your groceries delivered to your doorstep.</li>
                <li>Buy Online, Pick Up In Store - Convenience at its best.</li>
                <li>Cool Discounts - Save more with our exclusive offers.</li>
                <li>Weekly Sales - Don't miss out on our special deals every week.</li>
                <li>And many more exciting features coming your way!</li>
            </ul>
            <div className={styles.buttoncontainer}>
            <button
                className={styles.button} // Apply the CSS class for the button
                onClick={() => navigate('/')}>
                Go Back to Homepage
            </button>
            </div>
        </div>
    );
};

export default SiteUpdates;
