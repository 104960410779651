// VendorSelect.js
import React from 'react';

const VendorSelect = ({ isLoading, vendors, selectedVendor, onVendorChange }) => {
    const handleChange = (e) => {
        onVendorChange(e.target.value);
    };

    return (
        <div className="vendor-select-container">
            {isLoading ? (
                <p>Loading vendors...</p>
            ) : (
                <select onChange={handleChange} value={selectedVendor} className="vendor-select">
                    <option value="">Select a Vendor</option>
                    {vendors.map(vendor => (
                        <option key={vendor.id} value={vendor.id}>{vendor.vendor_name}</option>
                    ))}
                </select>
            )}
        </div>
    );
};

export default VendorSelect;
