import React from 'react';
import { useAuth } from '../Auth/useAuth';
import { useCustomerData } from '../Dashboard/Hooks/useCustomerData';
import { useVendors, useProducts } from './useInventoryHooks';
import * as api from './api';
import './VendorSelection.css';
import VendorSelect from './VendorSelect'; // A new component for vendor selection
import ProductTable from './ProductTable'; // A new component for showing the product table
import ResponsiveAppBar from '../Navbar/ResponsiveAppBar';

const VendorSelection = () => {
    const { user } = useAuth();
    const { customerData } = useCustomerData(user);
    const { vendors, isLoading: isLoadingVendors } = useVendors();
    const [selectedVendor, setSelectedVendor] = React.useState('');
    const { products, isLoading: isLoadingProducts } = useProducts(selectedVendor);
    const [currentQuantities, setCurrentQuantities] = React.useState({});

    const handleVendorChange = (vendorId) => {
        setSelectedVendor(vendorId);
        setCurrentQuantities({});
    };

    const handleCurrentQuantityChange = (productId, currentQuantity) => {
        setCurrentQuantities({ ...currentQuantities, [productId]: currentQuantity });
    };

    const handleSaveCurrentQuantity = async (productId) => {
        const currentQuantity = currentQuantities[productId];
        if (!currentQuantity) {
            alert("Please enter a quantity before saving.");
            return;
        }

        try {
            await api.adjustInventory(productId, currentQuantity, user.accessToken);
            alert("Inventory adjusted successfully");
        } catch (error) {
            console.error("Failed to save current quantity:", error);
            alert("Failed to adjust inventory");
        }
    };

    return (
        <div className="vendor-selection-container">
            <ResponsiveAppBar user={customerData} />
            <div className="vendor-selection-content">
                <VendorSelect
                    isLoading={isLoadingVendors}
                    vendors={vendors}
                    selectedVendor={selectedVendor}
                    onVendorChange={handleVendorChange}
                />
                <ProductTable
                    isLoading={isLoadingProducts}
                    products={products}
                    currentQuantities={currentQuantities}
                    onCurrentQuantityChange={handleCurrentQuantityChange}
                    onSaveCurrentQuantity={handleSaveCurrentQuantity}
                />
            </div>
        </div>
    );
};

export default VendorSelection;
