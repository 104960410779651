const BASE_URL = 'https://api.fivespiceindiangrocery.com/api';

export const fetchAllCategories = async (accessToken) => {
    try {
        const response = await fetch(`${BASE_URL}/categorylookup`, {
            headers: { 'Authorization': accessToken },
        });
        if (!response.ok) throw new Error('Network response was not ok');
        return response.json();
    } catch (error) {
        console.error("Failed to fetch categories:", error);
        throw error;
    }
};