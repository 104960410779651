import { useState, useEffect, useRef } from 'react';
import { fetchUserTransactions } from '../API/apiService';
import { useCustomerExistence } from '../../../context/CustomerExistenceContext';

export const useCustomerTransactions = (currentUser) => {
    const [transactions, setTransactions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const { doesCustomerExist, isCustomerDataLoaded } = useCustomerExistence();
    const hasFetchedTransactions = useRef(false); // Track if transactions have been fetched

    useEffect(() => {
        if (currentUser && doesCustomerExist && isCustomerDataLoaded && !hasFetchedTransactions.current) {
            setIsLoading(true);
            console.log('Fetching transactions for user:', currentUser);

            currentUser.getIdToken()
                .then(token => {
                    const base64Url = token.split('.')[1];
                    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
                    const payload = JSON.parse(atob(base64));
                    const customerId = payload.customerId;

                    if (customerId) {
                        console.log('Decoded token with customerId:', payload);
                        return fetchUserTransactions(token, customerId);
                    } else {
                        console.log('customerId not found in token, skipping transactions fetch');
                        return Promise.reject(new Error('No customerId in token'));
                    }
                })
                .then(data => {
                    setTransactions(data);
                    hasFetchedTransactions.current = true; // Mark as fetched
                })
                .catch(error => {
                    console.error('Error in useCustomerTransactions:', error);
                    setError(error);
                })
                .finally(() => setIsLoading(false));
        } else {
            if (!currentUser) {
                console.log('No current user or customer does not exist, skipping transactions fetch');
            }
            if (hasFetchedTransactions.current) {
                console.log('Transactions already fetched for current session, skipping.');
            }
            setIsLoading(false);
        }
    }, [currentUser, doesCustomerExist, isCustomerDataLoaded]);

    return { transactions, isLoading, error };
};
