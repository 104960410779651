import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import ResponsiveAppBar from '../Navbar/ResponsiveAppBar';
import { useCustomerData } from '../Dashboard/Hooks/useCustomerData';
import { useAuth } from '../../context/AuthContext';
import CustomAppBar from './CustomAppBar';
import InvoiceDisplay from './InvoiceDisplay';
import ProductsList from './ProductsList';
import createApi from './api';

const InvoiceMainPage = () => {
    const { currentUser } = useAuth();
    const { customerData } = useCustomerData(currentUser);
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [selectedMailbox, setSelectedMailbox] = useState(null);
    const [invoiceData, setInvoiceData] = useState(null);
    const [products, setProducts] = useState([]);
    const [mailboxes, setMailboxes] = useState([]);
    const [documents, setDocuments] = useState([]);
    const [error, setError] = useState(null);

    const api = createApi(currentUser.stsTokenManager.accessToken);

    useEffect(() => {
        fetchMailboxes();
    }, []);

    const fetchMailboxes = async () => {
        try {
            const data = await api.getMailboxes();
            setMailboxes(data);
        } catch (error) {
            setError('Failed to fetch mailboxes');
        }
    };

    const fetchDocuments = async (mailboxId) => {
        try {
            const data = await api.getDocuments(mailboxId);
            setDocuments(data);
        } catch (error) {
            setError('Failed to fetch documents');
        }
    };

    const fetchInvoiceData = async (docId) => {
        try {
            const data = await api.getInvoiceData(docId);
            setInvoiceData(data);
            setProducts(data.payload.parsed.Items || []);
        } catch (error) {
            setError('Failed to fetch invoice data');
        }
    };

    const handleAddProduct = (product) => {
        setProducts([...products, product]);
    };

    const handleSearch = (searchText) => {
        // Implement search functionality here
        console.log('Search text:', searchText);
    };

    const handleSelectMailbox = (mailbox) => {
        console.log('Selected mailbox:', mailbox);
        setSelectedMailbox(mailbox); // Make sure this is called
        fetchDocuments(mailbox._id);
    };

    const handleSelectDocument = (document) => {
        console.log('Selected document:', document);
        setSelectedDocument(document);
        fetchInvoiceData(document._id);
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <ResponsiveAppBar user={customerData} />
            <CustomAppBar
                onSearch={handleSearch}
                mailboxes={mailboxes}
                onSelectMailbox={handleSelectMailbox}
                documents={documents}
                onSelectDocument={handleSelectDocument}
                selectedMailbox={selectedMailbox}
                selectedDocument={selectedDocument}
            />
            {error && <div>Error: {error}</div>}
            <div style={{ display: 'flex', height: 'calc(100vh - 128px)' }}>
                <div style={{ flex: 1, overflow: 'auto' }}>
                    <InvoiceDisplay invoiceData={invoiceData} />
                </div>
                <div style={{ flex: 1, overflow: 'auto' }}>
                    <ProductsList products={products} onAddProduct={handleAddProduct} />
                </div>
            </div>
        </Box>
    );
};

export default InvoiceMainPage;
