// ProductNameField.js
import React from 'react';
import { TextField } from '@mui/material';

const ProductNameField = ({ productName, setProductName }) => (
  <TextField
    required
    fullWidth
    label="Product Name"
    value={productName}
    onChange={(event) => setProductName(event.target.value)}
    margin="normal"
  />
);

export default ProductNameField;
