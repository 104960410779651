import React, { useState } from 'react';
import { Box, Typography, Button, List, ListItem, TextField, ListItemSecondaryAction, ListItemText, Paper } from '@mui/material';
import useProductLogic from './useProductLogic';

const ProductsList = ({ products }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const filteredProducts = useProductLogic(products, searchTerm);

    const handleQuantityChange = (product, quantity) => {
        // Implement quantity change logic here
        console.log(`Quantity changed for ${product.name}: ${quantity}`);
    };

    return (
        <Box p={3}>
            <Typography variant="h6" gutterBottom>
                Invoice Items
            </Typography>
            <TextField
                fullWidth
                label="Search Products"
                variant="outlined"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                sx={{ mb: 2 }}
            />
            <Paper elevation={3}>
                <List>
                    {filteredProducts.map((product, index) => (
                        <ListItem key={index} divider>
                            <ListItemText
                                primary={product.name}
                                secondary={
                                    <>
                                        <Typography component="span" variant="body2" color="text.primary">
                                            Product Code: {product.productCode || 'N/A'}
                                        </Typography>
                                        <br />
                                        <Typography component="span" variant="body2">
                                            Unit Price: ${product.unitPrice.toFixed(2)} | 
                                            Total: ${product.totalAmount.toFixed(2)}
                                        </Typography>
                                    </>
                                }
                            />
                            <TextField
                                type="number"
                                value={product.quantity}
                                onChange={(e) => handleQuantityChange(product, e.target.value)}
                                inputProps={{ min: 0, style: { textAlign: 'center' } }}
                                sx={{ width: '80px', marginRight: '20px' }}
                            />
                            <ListItemSecondaryAction>
                                <Button variant="outlined" color="primary">
                                    Update
                                </Button>
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
                </List>
            </Paper>
        </Box>
    );
};

export default ProductsList;