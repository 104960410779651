import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import { CircularProgress, Box } from '@mui/material';


const LastTransactionAtGlance = ({ lastTransaction, isLoading }) => {
  // Check if lastTransaction exists and has a total property
  if (!lastTransaction || typeof lastTransaction.finalTotal === 'undefined') return null;

  // Format total to ensure it is a number and to control the display format
  const formattedTotal = Number(lastTransaction.finalTotal).toFixed(2);

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" minHeight="100px">
        <CircularProgress /> {/* Optionally, display a message or spinner */}
      </Box>
    );
  }

  return (
    <Card sx={{ minWidth: 275, margin: 2 }}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Last Transaction
        </Typography>
        <Typography variant="h5" component="div">
          {lastTransaction.updatedDate ? new Date(lastTransaction.updatedDate).toLocaleDateString() : 'Unknown Date'}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          Total: ${formattedTotal}
        </Typography>
        {/* Additional transaction details can be added here */}
      </CardContent>
    </Card>
  );
};

export default LastTransactionAtGlance;
