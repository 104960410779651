import React, { useState } from 'react';
import { Card, CardActionArea, Typography, Box, CircularProgress } from '@mui/material'; // Import CircularProgress for loading indicator
import UserTransactionsModal from './TransactionModal/UserTransactionsModal';

const UserTransactions = ({ transactions, isLoading }) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [currentTransaction, setCurrentTransaction] = useState(null);

    const toggleModal = (transaction) => {
        setCurrentTransaction(transaction);
        setModalIsOpen(!modalIsOpen);
    };

    // Determine if there are any transactions
    const hasTransactions = transactions && transactions.length > 0;
    console.log(transactions)

    return (
        <Box sx={{ margin: 2 }}>
            <Typography variant="h4" gutterBottom>
                {isLoading ? 'Transactions Loading...' : hasTransactions ? 'Your Transactions' : 'No Transactions'}
            </Typography>
            {isLoading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
                    <CircularProgress />
                </Box>
            ) : hasTransactions ? (
                transactions.slice(0, 10).map((transaction) => (
                    <Card key={transaction.orderId} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                        <CardActionArea onClick={() => toggleModal(transaction)} sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                            <Box sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                                <Typography variant="h6">
                                    Order #{transaction.orderId}
                                </Typography>
                                <Typography variant="body1">
                                    {new Date(transaction.createdDate).toLocaleDateString()}
                                </Typography>
                            </Box>
                            <Typography variant="body1" sx={{ p: 2, textAlign: 'right' }}>
                                Final Total: ${transaction.finalTotal.toFixed(2)}
                            </Typography>
                        </CardActionArea>
                    </Card>
                ))
            ) : null}

            {/* Modal for transaction details */}
            <UserTransactionsModal
                isOpen={modalIsOpen}
                onRequestClose={() => setModalIsOpen(false)}
                transaction={currentTransaction}
            />
        </Box>
    );
};

export default UserTransactions;
