// useAuth.js
import { useState, useEffect } from 'react';
import { getAuth, signOut } from 'firebase/auth';

export const useAuth = () => {
    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true); // Add a loading state
    const auth = getAuth();

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            setUser(user);
            setIsLoading(false); // Set loading to false once the user is resolved
        });

        // Clean up the subscription
        return () => unsubscribe();
    }, []);

    const logout = () => {
        signOut(auth);
    };

    return { user, isLoading, logout };
};