import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../Auth/useAuth'; // Import useAuth
import styles from './NewCustomerPage.module.css'; // Import the CSS module


const NewCustomerPage = () => {
    const [formData, setFormData] = useState({ first_name: '', last_name: '', email: '' });
    const { user } = useAuth(); // Get the user from useAuth
    const navigate = useNavigate();

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!user) {
            console.error('No user logged in');
            return;
        }

        try {
            const idToken = await user.getIdToken(); // Get the token from the user

            const response = await fetch('https://api.fivespiceindiangrocery.com/api/createcustomer', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': idToken,
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                navigate('/dashboard'); // Redirect to dashboard on successful creation
            } else {
                // Handle errors
                console.error('Failed to create customer');
            }
        } catch (error) {
            console.error('Error in submitting form:', error);
        }
    };

    return (
        <div className={styles.container}>
            <h2 className={styles.title}>New Customer Information</h2>
            <form onSubmit={handleSubmit} className={styles.form}>
                <input
                    type="text"
                    name="first_name"
                    placeholder="First Name"
                    value={formData.first_name}
                    onChange={handleChange}
                    required
                    className={styles.inputField}
                />
                <input
                    type="text"
                    name="last_name"
                    placeholder="Last Name"
                    value={formData.last_name}
                    onChange={handleChange}
                    required
                    className={styles.inputField}
                />
                <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                    className={styles.inputField}
                />
                <button type="submit" className={styles.submitButton}>Create Customer</button>
            </form>
        </div>
    );
};

export default NewCustomerPage;
