// CreateProductModal/CategorySelection.js
import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, CircularProgress } from '@mui/material';

const CategorySelection = ({ isLoading, categories, selectedCategory, setSelectedCategory }) => (
    <FormControl fullWidth margin="normal">
        <InputLabel>Category</InputLabel>
        {isLoading ? (
            <CircularProgress size={24} />
        ) : (
            <Select
                value={selectedCategory}
                label="Category"
                onChange={(event) => setSelectedCategory(event.target.value)}
            >
                {categories.map((category) => (
                    <MenuItem key={category.id} value={category.id}>
                        {category.name}
                    </MenuItem>
                ))}
            </Select>
        )}
    </FormControl>
);

export default CategorySelection;
