import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { AppBar, Box, Toolbar, IconButton, Typography, Menu, Container, Avatar, Tooltip, MenuItem, Button } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle'; // Import for login button icon
import { useAuth } from '../Auth/useAuth';
// Adjust the path as needed for logo import
import logo from '../../assets/FiveSpiceHorizontal.svg';

function ResponsiveAppBar({ user }) {
    const { logout } = useAuth();
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const navigate = useNavigate();

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleLogoutClick = async () => {
        handleCloseUserMenu();
        await logout(); // Call the logout method from useAuth hook directly
        navigate('/'); // Redirect to the homepage after logging out
    };

    const navigateToDashboard = () => {
        navigate('/dashboard'); // Redirect to the homepage after logging out
    };


    const navigateToLogin = () => {
        navigate('/login');
    };

    // Show Login button if no user is logged in, otherwise show user menu with avatar
    const renderUserSection = () => {
        if (!user) {
            return (
                <Button
                    startIcon={<AccountCircleIcon />}
                    onClick={navigateToLogin}
                    variant="outlined"
                    sx={(theme) => ({
                        color: theme.palette.mode === "dark" ? "white" : "black",
                        borderColor: theme.palette.mode === "dark" ? "white" : "black",
                        '&:hover': {
                            backgroundColor: theme.palette.mode === "dark" ? "rgba(255, 255, 255, 0.08)" : "rgba(0, 0, 0, 0.04)",
                            borderColor: theme.palette.mode === "dark" ? "white" : "black",
                        },
                        fontWeight: 'bold',
                    })}
                >
                    Log In
                </Button>
            );
        }

        const avatarContent = user.image ? (
            <Avatar alt="User" src={user.image} />
        ) : (
            <Avatar>{user.customer?.first_name?.charAt(0) ?? 'A'}</Avatar>
        );

        return (
            <React.Fragment>
                <Tooltip title="Open settings">
                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                        {avatarContent}
                    </IconButton>
                </Tooltip>
                <Menu
                    sx={{ mt: '45px' }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                >
                    <MenuItem onClick={navigateToDashboard}>
                        <Typography textAlign="center">Your Account</Typography>
                    </MenuItem>
                    <MenuItem onClick={handleLogoutClick}>
                        <Typography textAlign="center">Logout</Typography>
                    </MenuItem>
                </Menu>
            </React.Fragment>
        );
    };

    return (
        <AppBar position="static" sx={{ backgroundColor: 'white' }}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    {/* Logo */}
                    <Box onClick={navigateToLogin} sx={{ flexGrow: 1, display: 'flex' }}>
                        <img src={logo} alt="Logo" style={{ maxHeight: '60px' }} />
                    </Box>

                    {/* User Section: Avatar or Login Button */}
                    <Box sx={{ flexGrow: 0 }}>
                        {renderUserSection()}
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default ResponsiveAppBar;
