// CreateProductModal/SubcategorySelection.js
import React from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const SubcategorySelection = ({ subCategories, selectedSubCategory, setSelectedSubCategory, disabled }) => (
    <FormControl fullWidth margin="normal">
        <InputLabel>Subcategory</InputLabel>
        <Select
            value={selectedSubCategory}
            label="Subcategory"
            onChange={(event) => setSelectedSubCategory(event.target.value)}
            disabled={disabled}
        >
            {subCategories.map((subCategory) => (
                <MenuItem key={subCategory.id} value={subCategory.id}>
                    {subCategory.name}
                </MenuItem>
            ))}
        </Select>
    </FormControl>
);

export default SubcategorySelection;
