import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Typography, TextField, Button, Menu, MenuItem, Badge } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const CustomAppBar = ({ onSearch, mailboxes, documents, onSelectMailbox, onSelectDocument, selectedMailbox, selectedDocument }) => {
    const theme = useTheme();
    const [anchorElMailbox, setAnchorElMailbox] = useState(null);
    const [anchorElDocument, setAnchorElDocument] = useState(null);

    const handleMailboxClick = (event) => {
        console.log('Mailbox button clicked');
        setAnchorElMailbox(event.currentTarget);
    };

    const handleMailboxClose = () => {
        console.log('Mailbox menu closed');
        setAnchorElMailbox(null);
    };

    const handleDocumentClick = (event) => {
        console.log('Document button clicked');
        setAnchorElDocument(event.currentTarget);
    };

    const handleDocumentClose = () => {
        console.log('Document menu closed');
        setAnchorElDocument(null);
    };

    const handleMailboxSelect = (mailbox) => {
        console.log('Mailbox selected:', mailbox);
        onSelectMailbox(mailbox);
        handleMailboxClose();
    };

    const handleDocumentSelect = (document) => {
        console.log('Document selected:', document);
        onSelectDocument(document);
        handleDocumentClose();
    };

    useEffect(() => {
        console.log('Selected Mailbox:', selectedMailbox);
    }, [selectedMailbox]);

    useEffect(() => {
        console.log('Selected Document:', selectedDocument);
    }, [selectedDocument]);

    return (
        <AppBar position="static" style={{ backgroundColor: theme.palette.secondary.main }}>
            <Toolbar>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    Invoice Management
                </Typography>
                <TextField
                    label="Search Products"
                    variant="outlined"
                    size="small"
                    sx={{
                        width: 250,
                        backgroundColor: 'background.paper',
                        borderRadius: 1,
                        marginRight: '10px'
                    }}
                    onChange={(e) => onSearch(e.target.value)}
                />
                <Button onClick={handleMailboxClick}>
                    {selectedMailbox ? selectedMailbox.name : 'Select Mailbox'}
                </Button>
                <Menu
                    anchorEl={anchorElMailbox}
                    open={Boolean(anchorElMailbox)}
                    onClose={handleMailboxClose}
                >
                    {mailboxes.map((mailbox) => (
                        <MenuItem key={mailbox._id} onClick={() => handleMailboxSelect(mailbox)}>
                            <Badge badgeContent={mailbox.stats.docs_parsed} color="primary">
                                {mailbox.name}
                            </Badge>
                        </MenuItem>
                    ))}
                </Menu>
                <Button
                    onClick={handleDocumentClick}
                    disabled={documents.length === 0}
                >
                    {selectedDocument ? selectedDocument.name : 'Select Document'}
                </Button>
                <Menu
                    anchorEl={anchorElDocument}
                    open={Boolean(anchorElDocument)}
                    onClose={handleDocumentClose}
                >
                    {documents.map((document) => (
                        <MenuItem key={document._id} onClick={() => handleDocumentSelect(document)}>
                            {document.name}
                        </MenuItem>
                    ))}
                </Menu>
            </Toolbar>
        </AppBar>
    );
};

export default CustomAppBar;
