const BASE_URL = 'https://api.fivespiceindiangrocery.com/api';

// Fetch vendors
export const fetchVendors = async (accessToken) => {
    try {
        const response = await fetch(`${BASE_URL}/vendors`, {
            headers: { 'Authorization': accessToken },
        });
        if (!response.ok) throw new Error('Network response was not ok');
        return response.json();
    } catch (error) {
        console.error("Failed to fetch vendors:", error);
        throw error;
    }
};

// Fetch products
export const fetchProducts = async (vendorId, accessToken) => {
    try {
        const response = await fetch(`${BASE_URL}/vendors/${vendorId}`, {
            headers: { 'Authorization': accessToken },
        });
        if (!response.ok) throw new Error('Network response was not ok');
        return response.json();
    } catch (error) {
        console.error("Failed to fetch products:", error);
        throw error;
    }
};

// Adjust inventory
export const adjustInventory = async (productId, qty, accessToken) => {
    try {
        const response = await fetch(`${BASE_URL}/adjust-inventory/${productId}`, {
            method: 'POST',
            headers: {
                'Authorization': accessToken,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ qty }),
        });
        if (!response.ok) throw new Error('Failed to adjust inventory');
        return response.json();
    } catch (error) {
        console.error("Failed to adjust inventory:", error);
        throw error;
    }
};
//Fetch Item History
export const fetchItemHistory = async (itemId, accessToken) => {
    try {
        // Constructing the URL with the itemId
        const url = `${BASE_URL}/itemhistory/${itemId}`;
        
        const response = await fetch(url, {
            headers: { 'Authorization': accessToken },
        });

        if (!response.ok) throw new Error('Network response was not ok');
        
        const data = await response.json();

        // Assuming the API does not support date filtering and returns an array of history objects
        // Filter the data to the last three days on the client side if necessary
        // This example assumes each history object has a 'date' property in 'YYYY-MM-DD' format
        console.log(data)
        return data;
    } catch (error) {
        console.error("Failed to fetch item history:", error);
        throw error;
    }
};