import React from 'react';
import { Paper, Typography, Grid, TextField, MenuItem, Select, Button } from '@mui/material';

function PriceCalculator({ cost, setCost, productType, setProductType, handleCalculate }) {
  return (
    <Paper elevation={3} style={{ padding: '1rem', marginBottom: '1rem' }}>
      <Typography variant="h6" gutterBottom>Price Calculator</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            label="Enter Product Cost"
            type="number"
            inputProps={{ min: "0", step: "0.01" }}
            value={cost}
            onChange={(e) => setCost(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Select
            id="productType"
            value={productType}
            onChange={(e) => setProductType(e.target.value)}
            fullWidth
          >
            <MenuItem value="regular">Regular</MenuItem>
            <MenuItem value="vegetable">Vegetable</MenuItem>
          </Select>
        </Grid>
      </Grid>
      <Button variant="contained" color="primary" onClick={handleCalculate} fullWidth style={{ marginTop: '1rem' }}>
        Calculate Price
      </Button>
    </Paper>
  );
}

export default PriceCalculator;