import React from 'react';
import { Paper, Typography, Grid, CardMedia, Divider, Button, CircularProgress } from '@mui/material';

function ProductDetails({ productDetails, calculatedPrice, updating, updateProductPrice }) {
  if (!productDetails) return null;

  return (
    <Paper elevation={3} style={{ padding: '1rem', marginBottom: '1rem' }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <CardMedia
            component="img"
            height="250"
            image={productDetails.imageUrls?.[0] || 'placeholder-image-url'}
            alt={productDetails.itemName}
            style={{ objectFit: 'contain', borderRadius: '8px' }}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <Typography variant="h5" gutterBottom>{productDetails.itemName}</Typography>
          <Typography variant="subtitle1">Variation: {productDetails.variationName}</Typography>
          <Typography>SKU: {productDetails.sku}</Typography>
          <Typography>UPC: {productDetails.upc}</Typography>
          <Divider style={{ margin: '16px 0' }} />
          <Typography variant="h6">Pricing Information</Typography>
          <Typography>Default Price: ${productDetails.defaultPrice?.toFixed(2) ?? 'N/A'}</Typography>
          <Typography>San Jose Price: ${productDetails.locationSpecificPrice?.toFixed(2) ?? 'N/A'}</Typography>
          <Typography>Stock Level: {productDetails.stockLevel}</Typography>
          {calculatedPrice && (
            <Typography color="primary" variant="h6" style={{ marginTop: '16px' }}>
              Calculated Price: ${calculatedPrice.toFixed(2)}
            </Typography>
          )}
          <Grid container spacing={2} style={{ marginTop: '16px' }}>
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => updateProductPrice(calculatedPrice || productDetails.locationSpecificPrice)}
                disabled={updating || !calculatedPrice}
                fullWidth
              >
                {updating ? <CircularProgress size={24} /> : 'Update San Jose Price'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default ProductDetails;