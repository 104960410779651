import { createTheme } from '@mui/material/styles';

// Define colors from the logo
const primaryRed = '#e53935'; // Replace with the exact red from the logo
const secondaryGreen = '#43a047'; // Replace with the exact green from the logo
const accentOrange = '#fb8c00'; // Replace with the exact orange from the logo
const neutralWhite = '#fafafa';
const textGray = '#424242';

// Create a theme instance
const theme = createTheme({
  palette: {
    primary: {
      main: primaryRed,
    },
    secondary: {
      main: secondaryGreen,
    },
    warning: {
      main: accentOrange,
    },
    background: {
      default: neutralWhite,
      paper: neutralWhite,
    },
    text: {
      primary: textGray,
      secondary: 'rgba(0, 0, 0, 0.54)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: '2.5rem',
      fontWeight: 500,
      color: primaryRed,
    },
    // ... define other typography styles
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          textTransform: 'none',
        },
        containedPrimary: {
          backgroundColor: primaryRed,
          '&:hover': {
            backgroundColor: '#d32f2f', // darker shade of red
          },
        },
        containedSecondary: {
          backgroundColor: secondaryGreen,
          '&:hover': {
            backgroundColor: '#388e3c', // darker shade of green
          },
        },
      },
    },
    // ... override styles for other components
  },
});

export default theme;