import React, { useState, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { Box, IconButton, Tooltip, Typography, AppBar, Toolbar, Paper } from '@mui/material';
import { ZoomIn, ZoomOut, ArrowBack, ArrowForward, Fullscreen, FullscreenExit } from '@mui/icons-material';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
).toString();

const InvoiceDisplay = ({ invoiceData }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [downloadUrl, setDownloadUrl] = useState(null);
  const [pdfError, setPdfError] = useState(null);
  const [scale, setScale] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [isFullScreen, setIsFullScreen] = useState(false);

  useEffect(() => {
    if (invoiceData && invoiceData.payload) {
      const url = invoiceData.payload.parsed.download_url;
      setDownloadUrl(url);
    }
  }, [invoiceData]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPdfError(null);
  };

  const onDocumentLoadError = (error) => {
    setPdfError(error);
  };

  const handleZoomIn = () => setScale(prevScale => Math.min(prevScale + 0.1, 2));
  const handleZoomOut = () => setScale(prevScale => Math.max(prevScale - 0.1, 0.5));
  const handleRotateLeft = () => setRotation(prevRotation => (prevRotation - 90) % 360);
  const handleRotateRight = () => setRotation(prevRotation => (prevRotation + 90) % 360);
  const handleFullScreenToggle = () => setIsFullScreen(!isFullScreen);

  const handleKeyDown = (event) => {
    switch (event.key) {
      case 'ArrowLeft':
        if (pageNumber > 1) setPageNumber(prevPage => prevPage - 1);
        break;
      case 'ArrowRight':
        if (pageNumber < numPages) setPageNumber(prevPage => prevPage + 1);
        break;
      case '+':
        handleZoomIn();
        break;
      case '-':
        handleZoomOut();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [pageNumber, numPages]);

  if (!invoiceData || !invoiceData.payload) {
    return (
      <Box p={3}>
        <Typography variant="h5">Select a document to view details</Typography>
      </Box>
    );
  }

  return (
    <Box display="flex" flexDirection="column" height="100vh" bgcolor="#f5f5f5">
      <AppBar position="sticky">
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            Invoice Viewer
          </Typography>
          <Tooltip title="Previous Page">
            <span>
              <IconButton disabled={pageNumber <= 1} onClick={() => setPageNumber(prevPage => prevPage - 1)}>
                <ArrowBack />
              </IconButton>
            </span>
          </Tooltip>
          <Typography variant="body1" sx={{ mx: 2 }}>
            Page {pageNumber} of {numPages}
          </Typography>
          <Tooltip title="Next Page">
            <span>
              <IconButton disabled={pageNumber >= numPages} onClick={() => setPageNumber(prevPage => prevPage + 1)}>
                <ArrowForward />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Zoom Out">
            <IconButton onClick={handleZoomOut}>
              <ZoomOut />
            </IconButton>
          </Tooltip>
          <Tooltip title="Zoom In">
            <IconButton onClick={handleZoomIn}>
              <ZoomIn />
            </IconButton>
          </Tooltip>
          <Tooltip title="Toggle Fullscreen">
            <IconButton color="inherit" onClick={handleFullScreenToggle}>
              {isFullScreen ? <FullscreenExit /> : <Fullscreen />}
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
      <Box flex={1} overflow="auto" p={2} bgcolor="#ffffff">
        <Paper elevation={3} sx={{ p: 2, overflow: 'auto', position: 'relative', ...(isFullScreen && { height: 'calc(100vh - 64px)', width: '100%' }) }}>
          {pdfError ? (
            <Typography color="error">Error loading PDF: {pdfError.message}</Typography>
          ) : (
            <Document
              file={downloadUrl}
              onLoadSuccess={onDocumentLoadSuccess}
              onLoadError={onDocumentLoadError}
              loading={<Typography>Loading PDF...</Typography>}
            >
              <Page 
                pageNumber={pageNumber} 
                scale={scale}
                rotate={rotation}
                width={isFullScreen ? window.innerWidth - 32 : 600}
              />
            </Document>
          )}
        </Paper>
      </Box>
    </Box>
  );
};

export default InvoiceDisplay;
