import { useState, useEffect } from 'react';
import * as api from './api'; // Assuming api.js is in the same directory
import { useAuth } from '../Auth/useAuth';


export const useVendors = () => {
    const { user } = useAuth();
    const [vendors, setVendors] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!user || !user.accessToken) return;
        setIsLoading(true);
        api.fetchVendors(user.accessToken)
            .then(setVendors)
            .catch(console.error)
            .finally(() => setIsLoading(false));
    }, [user]);

    return { vendors, isLoading };
};

export const useProducts = (vendorId) => {
    const { user } = useAuth();
    const [products, setProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!vendorId) return;
        setIsLoading(true);
        api.fetchProducts(vendorId, user.accessToken)
            .then(setProducts)
            .catch(console.error)
            .finally(() => setIsLoading(false));
    }, [vendorId, user]);

    return { products, isLoading };
};

export const useItemSales = (itemId) => {
    const { user } = useAuth(); // Utilize the existing authentication context
    const [salesData, setSalesData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    
    useEffect(() => {
        if (!user || !user.accessToken) return;
        setIsLoading(true);
        api.fetchItemHistory(itemId, user.accessToken) // Assuming this is the correct function call
            .then(setSalesData)
            .catch(console.error)
            .finally(() => setIsLoading(false));
    }, [itemId, user]);

    return { salesData, isLoading };
};
