import React from 'react';
import { useItemSales } from './useInventoryHooks'; // Ensure this path matches your file structure

const ItemHistory = ({ itemId, itemUOM }) => {
  const { salesData, isLoading } = useItemSales(itemId);

  if (isLoading) return <td colSpan="3">Loading...</td>; // Ensure the colspan matches your date columns

  // Dynamically determine unique dates within the sales data
  const getUniqueDates = (sales) => {
    const uniqueDates = sales.map(item => item.date).filter((value, index, self) => self.indexOf(value) === index);
    uniqueDates.sort((a, b) => new Date(a) - new Date(b)); // Chronological order
    return uniqueDates.slice(-3); // Get the last 3 dates
  };

  const uniqueDates = getUniqueDates(salesData);

  // Check for each date if there's data, else display "No sales"
  return (
    <>
      {uniqueDates.map(date => {
        const dataForDate = salesData.find(data => data.date === date);
        if (!dataForDate) {
          return <td key={date}>No sales</td>;
        }
        const displayValue = dataForDate.totalWeight || dataForDate.totalQuantity;
        const roundedValue = displayValue ? `${Math.round(displayValue)} ${itemUOM}` : "No sales";
        return <td key={date}>{roundedValue}</td>;
      })}
    </>
  );
};

export default ItemHistory;
