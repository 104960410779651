// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBsD5Z21_0CnXFOUl49cKp8OyMpt9t58j8",
  authDomain: "fsigcustomerapp.firebaseapp.com",
  projectId: "fsigcustomerapp",
  storageBucket: "fsigcustomerapp.appspot.com",
  messagingSenderId: "545255778774",
  appId: "1:545255778774:web:f4a342287cadea12909c77"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default app;
