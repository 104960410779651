import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './components/HomePage/HomePage';
import Login from './components/Login/Login';
import Dashboard from './components/Dashboard/Dashboard';
import NewCustomerPage from './components/NewCustomer/newCustomerPage';
import SiteUpdate from './components/site-updates/SiteUpdates'
import { AuthProvider } from './context/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';
import CostCalculator from './components/CostCalculatorFolder/CostCalculator'; // Import your new component
import { CustomerExistenceProvider } from './context/CustomerExistenceContext'; // Import the provider
import VendorSelection from './components/OrderTool/VendorOrderTool'
import InvoiceEntry from './components/Invoices/invoiceMainPage'
import ProduceDisplay from './components/ProduceDisplay/produceDisplay';
import Modal from 'react-modal';
import './firebase';

//Theme Imports
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme'; // Adjust the import path according to where you placed theme.js


Modal.setAppElement('#root'); // Replace '#root' with the correct ID of your app's root element

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <CustomerExistenceProvider> {/* Wrap with CustomerExistenceProvider */}
          <Router>
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/siteupdates" element={<SiteUpdate />} />
                <Route path="/login" element={<Login />} />
                <Route path="/dashboard" element={
                  <ProtectedRoute>
                    <Dashboard />
                  </ProtectedRoute>
                } />
                <Route path="/new-customer" element={
                  <ProtectedRoute>
                    <NewCustomerPage /> {/* Protected NewCustomerPage route */}
                  </ProtectedRoute>
                } />
                <Route path="/cost-calculator" element={<ProtectedRoute><CostCalculator /></ProtectedRoute>} />
                <Route path="/inventory" element={<ProtectedRoute><CostCalculator /></ProtectedRoute>} />
                <Route path="/invoice-entry" element={<InvoiceEntry />} />
                <Route path="/order-tool" element={<VendorSelection />} />
                <Route path="/produce" element={<ProduceDisplay />} />
              </Routes>
          </Router>
        </CustomerExistenceProvider>
      </AuthProvider>
    </ThemeProvider>

  );
}

export default App;
