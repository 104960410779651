// CreateProductModal/SKUField.js
import React from 'react';
import { TextField } from '@mui/material';

const SKUField = ({ sku, setSku }) => (
    <TextField
        required
        fullWidth
        label="SKU"
        value={sku}
        onChange={(event) => setSku(event.target.value)}
        margin="normal"
    />
);

export default SKUField;
