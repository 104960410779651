import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import styles from './HeroBanner.module.css';
// Main image import
import logo from '../../assets/FiveSpiceHorizontal.svg'; // Adjust this path based on your project structure

const Hero = () => {
    const navigate = useNavigate(); // Create navigate function

    // Handler for button click
    const handleButtonClick = () => {
        navigate('/siteupdates'); // Navigate to the "Site Updates" page
    };

    return (
        <div className={styles.heroWrapper}>
            <img src='https://lh3.googleusercontent.com/p/AF1QipPAuf0w9OVPy9IIrJNr5tzvX4ZT5kasosbZLAdX=s1360-w1360-h1020' alt="Hero Banner" className={styles.heroImage} />
            <div className={styles.textContainer}>
                <img src={logo} alt="Logo" className={styles.logo} />
                <p className={styles.description}>
                    It's back! After a brief hiatus, our website is back from the grave, we will be having some amazing things coming soon...
                </p>
                <button onClick={handleButtonClick} className={styles.button}>
                    Get Cultured
                </button>
            </div>
        </div>
    );
};

export default Hero;
