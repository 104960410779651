// Dashboard.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material'; // Import Grid from MUI
import ResponsiveAppBar from '../Navbar/ResponsiveAppBar';
import UserGreeting from './UserGreeting/UserGreeting'; // Adjust the path as necessary
import LastTransactionAtGlance from './lastTransactionAtAGlance'; // Import the new component
import UserTransactions from './UserTransactions/UserTransactions';
import { useAuth } from '../../context/AuthContext';
import { useCustomerData } from './Hooks/useCustomerData';
import { useCustomerTransactions } from './Hooks/useCustomerTransactions';
import EmployeeNavigation from './EmployeeNavigation';
import { jwtDecode } from "jwt-decode";

import CreateProductModal from './Modals/CreateProductModal'; // Ensure this path is correct




const Dashboard = () => {
    const { currentUser } = useAuth();
    console.log("this ran", currentUser)
    const { customerData } = useCustomerData(currentUser);
    const { transactions, isLoading: isTransactionsLoading } = useCustomerTransactions(currentUser);
    const [isEmployee, setIsEmployee] = useState(false);
    const navigate = useNavigate();

    const [showCreateProductModal, setShowCreateProductModal] = useState(false);

    const handleOpenCreateProductModal = () => setShowCreateProductModal(true);
    const handleCloseCreateProductModal = () => setShowCreateProductModal(false);

    useEffect(() => {
        if (!currentUser) {
            navigate('/');
            return;
        }
        if (currentUser && currentUser.accessToken) {
            const decodedToken = jwtDecode(currentUser.accessToken);
            setIsEmployee(decodedToken.isEmployee === true); // Assuming 'employee' is a boolean in the decoded token
        }
    }, [currentUser, navigate]);

    // Assuming the transactions are sorted and the first one is the last transaction
    const lastTransaction = transactions && transactions.length > 0 ? transactions[0] : null;

    return (
        <div>
            <ResponsiveAppBar user={customerData} />
            {isEmployee && (
                <EmployeeNavigation
                    // You will need to define these functions to handle opening each modal
                    onOpenCreateProduct={handleOpenCreateProductModal}
                    onOpenCheckPrice={() => { }}
                    onOpenEmployeeDashboard={() => { }}
                    onOpenEmployeeInfo={() => { }}
                />
            )}
            <CreateProductModal
                open={showCreateProductModal}
                onClose={handleCloseCreateProductModal}
                accessToken={currentUser?.accessToken} // Pass accessToken here
            />
            <Grid container spacing={2} sx={{ padding: 2 }}>
                <Grid item xs={12} md={6}>
                    <UserGreeting customerData={customerData} />
                </Grid>
                <Grid item xs={12} md={6}>
                    {lastTransaction && <LastTransactionAtGlance lastTransaction={lastTransaction} isLoading={isTransactionsLoading} />}
                </Grid>
                <Grid item xs={12}>
                    <UserTransactions transactions={transactions} isLoading={isTransactionsLoading} />
                </Grid>
            </Grid>
        </div>
    );
};

export default Dashboard;
