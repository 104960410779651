import React, { useEffect, useState } from 'react';
import { Modal, Box, Typography, Button, CircularProgress, useTheme, useMediaQuery } from '@mui/material';
import { fetchAllCategories } from './api/api'; // Adjust the import path as needed
import ProductNameField from './CreateProductModal/ProductNameField';
import ProductPriceField from './CreateProductModal/ProductPriceField';
import ProductCostField from './CreateProductModal/ProductCostField'; // Make sure to create and import this
import SKUField from './CreateProductModal/SKUField'; // Make sure to create and import this
import BarcodeField from './CreateProductModal/BarcodeField';
import CategorySelection from './CreateProductModal/CategorySelection'; // Make sure to create and import this
import SubcategorySelection from './CreateProductModal/SubcategorySelection'; // Make sure to create and import this

const CreateProductModal = ({ open, onClose, accessToken }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [subCategories, setSubCategories] = useState([]);
    const [selectedSubCategory, setSelectedSubCategory] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    // State for form fields
    const [productName, setProductName] = useState('');
    const [productPrice, setProductPrice] = useState('');
    const [productCost, setProductCost] = useState('');
    const [barcode, setBarcode] = useState('');
    const [sku, setSku] = useState('');
    //For Steps
    const [step, setStep] = useState(1); // Start at step 1

    useEffect(() => {
        if (open) {
            setIsLoading(true);
            fetchAllCategories(accessToken)
                .then(setCategories)
                .catch(error => {
                    console.error("Failed to fetch categories:", error);
                    setIsLoading(false);
                })
                .finally(() => setIsLoading(false));
        }
    }, [open, accessToken]);

    useEffect(() => {
        // Reset subcategories when category changes
        const category = categories.find(c => c.id === selectedCategory);
        setSubCategories(category ? category.subCategories : []);
        setSelectedSubCategory('');
    }, [selectedCategory, categories]);

    const handleSubmit = (event) => {
        event.preventDefault();
        // Here you will handle form submission, potentially calling an API
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="create-product-modal-title"
            aria-describedby="create-product-modal-description"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: fullScreen ? '90%' : 400,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                overflowY: 'auto',
                maxHeight: '90vh',
            }}>
                <Typography id="create-product-modal-title" variant="h6" component="h2">
                    Create New Product
                </Typography>
                <form noValidate onSubmit={handleSubmit}>
                    <ProductNameField productName={productName} setProductName={setProductName} />
                    <ProductPriceField productPrice={productPrice} setProductPrice={setProductPrice} />
                    <ProductCostField productCost={productCost} setProductCost={setProductCost} />
                    <BarcodeField barcode={barcode} setBarcode={setBarcode} />
                    <SKUField sku={sku} setSku={setSku} />
                    <CategorySelection isLoading={isLoading} categories={categories} selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} />
                    <SubcategorySelection subCategories={subCategories} selectedSubCategory={selectedSubCategory} setSelectedSubCategory={setSelectedSubCategory} disabled={subCategories.length === 0 || isLoading} />
                    <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                        Create
                    </Button>
                    <Button fullWidth variant="outlined" sx={{ mt: 1, mb: 2 }} onClick={onClose}>
                        Cancel
                    </Button>
                </form>
            </Box>
        </Modal>
    );
};

export default CreateProductModal;
