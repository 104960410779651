import React, { useState } from 'react';
import { Paper, Typography, TextField, Grid, List, ListItem, ListItemText, IconButton, InputAdornment, Button, Box } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import BarcodeIcon from '@mui/icons-material/QrCodeScanner';

function ProductLookup({ searchQuery, setSearchQuery, barcode, setBarcode, handleSearch, handleBarcodeSearch, searchProducts, fetchProductData, searchResults, selectProduct, loading }) {
  const [showResults, setShowResults] = useState(false);

  const handleSearchClick = () => {
    handleSearch();
    setShowResults(true);
  };

  return (
    <Paper elevation={3} sx={{ p: 3, maxWidth: 600, margin: 'auto' }}>
      <Typography variant="h5" gutterBottom>
        Product Lookup
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Search Products"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleSearchClick} disabled={loading}>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Barcode"
            value={barcode}
            onChange={(e) => setBarcode(e.target.value)}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => fetchProductData(barcode)} disabled={loading}>
                    <BarcodeIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" onClick={handleSearchClick} fullWidth disabled={loading}>
            Search
          </Button>
        </Grid>
      </Grid>
      {showResults && (
        <Box sx={{ mt: 2, maxHeight: 300, overflowY: 'auto' }}>
          <List>
            {searchResults.map((product) => (
              <ListItem key={product.id} button onClick={() => selectProduct(product)}>
                <ListItemText primary={product.name} secondary={product.description} />
              </ListItem>
            ))}
          </List>
        </Box>
      )}
    </Paper>
  );
}

export default ProductLookup;