import React, { useEffect, useState } from 'react';
import { TextField, InputAdornment, IconButton, Dialog, DialogContent } from '@mui/material';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import Quagga from 'quagga'; // Ensure Quagga is correctly installed

const BarcodeScannerDialog = ({ open, onClose, onDetected }) => {
    useEffect(() => {
        let isQuaggaInitialized = false; // Track whether Quagga has been initialized

        if (open) {
            console.log('Initializing Quagga for barcode scanning...');
            Quagga.init({
                inputStream: {
                    type: "LiveStream",
                    constraints: {
                        width: 640,
                        height: 480,
                        facingMode: "environment",
                    },
                    target: document.querySelector('#barcode-scanner-container')
                },
                decoder: {
                    readers: ["code_128_reader", "ean_reader", "ean_8_reader"],
                },
            }, (err) => {
                if (err) {
                    console.error('Error initializing Quagga:', err);
                    return;
                }
                console.log('Quagga initialized successfully');
                Quagga.start();
                isQuaggaInitialized = true; // Update flag after successful initialization
            });

            Quagga.onDetected((result) => {
                console.log('Barcode detected:', result.codeResult.code);
                onDetected(result.codeResult.code);
            });
        }

        return () => {
            if (isQuaggaInitialized && Quagga) { // Only attempt to stop Quagga if it's initialized
                console.log('Stopping Quagga...');
                Quagga.stop();
                Quagga.offDetected(onDetected);
            }
        };
    }, [open, onDetected]);

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogContent>
                <div id="barcode-scanner-container" style={{ width: '100%', height: '100%' }}></div>
            </DialogContent>
        </Dialog>
    );
};

const BarcodeField = ({ barcode, setBarcode }) => {
  const [isScannerOpen, setIsScannerOpen] = useState(false);

  const handleDetected = (code) => {
    console.log('Setting scanned barcode to state:', code);
    setBarcode(code);
    setIsScannerOpen(false); // Close the scanner dialog
  };

  return (
    <>
      <TextField
        required
        fullWidth
        label="Barcode"
        value={barcode}
        onChange={(event) => setBarcode(event.target.value)}
        margin="normal"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => setIsScannerOpen(true)}>
                <CameraAltIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <BarcodeScannerDialog open={isScannerOpen} onClose={() => setIsScannerOpen(false)} onDetected={handleDetected} />
    </>
  );
};

export default BarcodeField;
