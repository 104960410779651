import React, { useState } from 'react';
import { Box, Button, Stack, Menu, MenuItem, useMediaQuery, useTheme } from '@mui/material';

const EmployeeNavigation = ({ onOpenCreateProduct, onOpenCheckPrice, onOpenEmployeeDashboard, onOpenEmployeeInfo }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuItems = [
    { name: 'Create New Product', action: onOpenCreateProduct },
    { name: 'Check Current Price', action: onOpenCheckPrice },
    { name: 'Employee Dashboard', action: onOpenEmployeeDashboard },
    { name: 'Employee Information', action: onOpenEmployeeInfo },
  ];

  return (
    <Box sx={{ padding: 2 }}>
      {isMobile ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
          <Button variant="contained" onClick={handleClick} sx={{ width: '100%' }}>
            Team Member Tools
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {menuItems.map((item) => (
              <MenuItem key={item.name} onClick={() => { handleClose(); item.action(); }}>
                {item.name}
              </MenuItem>
            ))}
          </Menu>
        </Box>
      ) : (
        <Stack direction="row" spacing={2} justifyContent="center">
          {menuItems.map((item) => (
            <Button key={item.name} variant="contained" onClick={item.action}>
              {item.name}
            </Button>
          ))}
        </Stack>
      )}
    </Box>
  );
};

export default EmployeeNavigation;
