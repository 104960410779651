// Invoices/api.js
import axios from 'axios';

const createApi = (token) => {
  const api = axios.create({
    baseURL: 'http://localhost:8080/api',
    headers: {
      'authorization': `${token}`
    }
  });

  return {
    getMailboxes: async () => {
      try {
        const response = await api.get('/mailboxes');
        console.log('Mailboxes data:', response.data);
        return response.data;
      } catch (error) {
        console.error('Error fetching mailboxes:', error.response?.data || error.message);
        throw error;
      }
    },

    getDocuments: async (mailboxId) => {
      try {
        const response = await api.get(`/mailboxes/${mailboxId}/docs`);
        console.log('Documents data:', response.data);
        return response.data.docs;
      } catch (error) {
        console.error('Error fetching documents:', error.response?.data || error.message);
        throw error;
      }
    },

    getInvoiceData: async (docId) => {
      try {
        const response = await api.get(`/parsedinvoices/${docId}`);
        console.log('Invoice data:', response.data);
        return response.data;
      } catch (error) {
        console.error('Error fetching invoice data:', error.response?.data || error.message);
        throw error;
      }
    }
  };
};

export default createApi;