import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import styles from './HomePage.module.css';
import Hero from './Carousel'; // You'll need to create this component
import Testimonials from './Testimonials'; // You'll need to create this component
import ResponsiveAppBar from '../Navbar/ResponsiveAppBar'
import { useCustomerData } from '../Dashboard/Hooks/useCustomerData';


const HomePage = () => {
    const { currentUser } = useAuth();
    const { customerData } = useCustomerData(currentUser);
    console.log(currentUser)
    const navigate = useNavigate();


    
        return (
            <div className={styles.HomePage}>
                <ResponsiveAppBar user={customerData}/>
                <Hero /> {/* Featured products or offers */}
                <footer className={styles.Footer}>
                    <p>© 2024 Five Spice Indian Grocery</p>
                    {/* Additional footer content */}
                </footer>
            </div>
        );
    
};

export default HomePage;
