// ProductPriceField.js
import React from 'react';
import { TextField, InputAdornment } from '@mui/material';

const ProductPriceField = ({ productPrice, setProductPrice }) => (
    <TextField
    required
    fullWidth
    label="Product Price"
    value={productPrice}
    onChange={(event) => setProductPrice(event.target.value)}
    type="number"
    InputProps={{
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
    }}
    margin="normal"
/>
);

export default ProductPriceField;