// CreateProductModal/ProductCostField.js
import React from 'react';
import { TextField, InputAdornment } from '@mui/material';

const ProductCostField = ({ productCost, setProductCost }) => (
    <TextField
        required
        fullWidth
        label="Product Cost"
        value={productCost}
        onChange={(event) => setProductCost(event.target.value)}
        type="number"
        InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
        margin="normal"
    />
);

export default ProductCostField;
