import React, { useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react'; // Import AG Grid React
import 'ag-grid-community/styles/ag-grid.css'; // Basic AG Grid styles
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Theme
import ItemHistory from './ItemHistory'; // Ensure this matches your file structure

const ProductTable = ({ isLoading, products }) => {
  const datesToCheck = [];
  for (let i = 0; i < 3; i++) {
    const date = new Date();
    date.setDate(date.getDate() - i);
    datesToCheck.push(date.toISOString().split('T')[0]);
  }

  // Configure columns for AG Grid
  const columns = useMemo(() => [
    { headerName: 'Product Name', field: 'name' },
    { headerName: 'Last Received', field: 'inventory.updated_date' },
    { headerName: 'Qty on Hand', field: 'inventory.quantity_in_hand' },
    { headerName: 'Minimum QTY', field: 'inventory.threshold' },
    {
      headerName: 'Current Quantity',
      field: 'currentQuantity',
      editable: true, // Makes the cell editable
      cellEditor: 'agTextCellEditor',
    },
    {
      headerName: 'Actions',
      cellRendererFramework: (params) => (
        <button onClick={() => console.log('Save', params.data)}>Save</button>
      ),
    },
    // Custom renderer for item history
    {
      headerName: 'Item History',
      cellRendererFramework: (params) => (
        <ItemHistory itemId={params.data.id} datesToCheck={datesToCheck} itemUOM={params.data.uom}/>
      ),
    },
    // Add more columns as needed
  ], [datesToCheck]);

  // Configure default column properties
  const defaultColDef = useMemo(() => ({
    resizable: true,
    sortable: true,
  }), []);

  return (
    <div className="ag-theme-alpine" style={{ height: 600, width: '100%' }}>
      {isLoading ? (
        <p>Loading products...</p>
      ) : (
        <AgGridReact
          rowData={products} // Provide data
          columnDefs={columns} // Provide columns configuration
          defaultColDef={defaultColDef} // Default column properties
          domLayout='autoHeight' // Adjust grid size automatically
        />
      )}
    </div>
  );
};

export default ProductTable;
