import React, { useState, useEffect,useCallback } from 'react';
import {
  Box,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  CircularProgress
} from '@mui/material';
import { styled } from '@mui/system';

const DisplayContainer = styled(Box)({
  width: '1920px',
  height: '1080px',
  overflow: 'hidden',
  backgroundColor: '#f0f0f0',
  padding: '20px',
  display: 'flex',
  flexDirection: 'column',
});

const StyledGrid = styled(Grid)({
  flexGrow: 1,
  overflow: 'hidden',
});

const StyledCard = styled(Card)({
  width: '260px',
  height: '180px',
  display: 'flex',
  flexDirection: 'column',
  margin: '5px',
});

const StyledCardMedia = styled(CardMedia)({
  height: '120px',
});

const StyledCardContent = styled(CardContent)({
  padding: '8px',
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
});

const ProductItem = ({ product }) => (
    <StyledCard>
    <StyledCardMedia
      image={product.imageUrl}
      title={product.name}
    />
    <StyledCardContent>
      <Box display="flex" flexDirection="column" justifyContent="space-between" height="100%">
        <Box>
          <Typography variant="subtitle2" noWrap>{product.name}</Typography>
          <Typography variant="caption" noWrap color="text.secondary">
            {product.variationName}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <Typography variant="body2" noWrap>
            ${product.price}/{product.unit === 'IMPERIAL_POUND (2 decimal places)' ? 'lbs' : product.unit}
          </Typography>
        </Box>
      </Box>
    </StyledCardContent>
  </StyledCard>
);

const ITEMS_PER_PAGE = 35; // 7x5 grid

const ProduceDisplay = () => {
  const [produceData, setProduceData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [currentCategoryIndex, setCurrentCategoryIndex] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = useCallback(() => {
    fetch('https://api.fivespiceindiangrocery.com/api/squareproduce')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setProduceData(prevData => {
          // Only update if the data has changed
          if (JSON.stringify(prevData) !== JSON.stringify(data)) {
            const uniqueCategories = [...new Set(data.map(item => item.categoryName))];
            setCategories(uniqueCategories);
            return data;
          }
          return prevData;
        });
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching produce data:', error);
        // Don't set error state here to avoid disrupting the display
      });
  }, []);

  // Initial data fetch
  useEffect(() => {
    setLoading(true);
    fetchData();
  }, [fetchData]);

  // Periodic data refresh
  useEffect(() => {
    const refreshInterval = setInterval(() => {
      console.log('Refreshing data...');
      fetchData();
    }, 200000); // 20 minutes in milliseconds

    return () => clearInterval(refreshInterval);
  }, [fetchData]);

  useEffect(() => {
    if (categories.length === 0) return;

    const timer = setInterval(() => {
      setCurrentPage(prevPage => {
        const currentProducts = produceData.filter(product => 
          product.categoryName === categories[currentCategoryIndex]
        );
        const pageCount = Math.ceil(currentProducts.length / ITEMS_PER_PAGE);
        
        if (prevPage + 1 < pageCount) {
          // Move to the next page of the current category
          return prevPage + 1;
        } else {
          // Move to the next category
          setCurrentCategoryIndex(prevCategoryIndex => 
            (prevCategoryIndex + 1) % categories.length
          );
          return 0; // Reset to the first page of the new category
        }
      });
    }, 15000);

    return () => clearInterval(timer);
  }, [categories, currentCategoryIndex, produceData]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  const currentProducts = produceData
    .filter(product => product.categoryName === categories[currentCategoryIndex]);

  const displayedProducts = currentProducts.slice(
    currentPage * ITEMS_PER_PAGE,
    (currentPage + 1) * ITEMS_PER_PAGE
  );

  return (
    <DisplayContainer>
      <Box display="flex" justifyContent="center" alignItems="center" mb={2}>
        <Typography variant="h3">{categories[currentCategoryIndex]}</Typography>
      </Box>
      <StyledGrid container justifyContent="center">
        {displayedProducts.map((product, index) => (
          <ProductItem key={index} product={product} />
        ))}
      </StyledGrid>
    </DisplayContainer>
  );
};

export default ProduceDisplay;